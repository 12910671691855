/* REF Expainsion
 * - https://codesandbox.io/s/6xjz837j9z
 * - https://codesandbox.io/s/nkn7mo55kl
 * - https://codesandbox.io/s/compact-expansionpanelsummary-z3lqz?fontsize=14
*/
import React, { useState }  from 'react';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withStyles } from '@material-ui/core/styles';
import Avatar from './Avatar';

const styles = (/*theme*/) => ({
    root: {
        width: "100%"
    },
    truncate: {
        width: 700,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tooltip: {
        /*
        display: 'flex',
        alignItems: 'center',
        */
        overflow: "hidden",
        textOverflow: "ellipsis",
        height: "28px", /* Just enough to show 1 lines */
    },
    list_avatar:{
        display:"flex",
        alignSelf: "flex-start",
    },
});

const EXPanelStyles = {
    root: {
        position:'static', /* remove top line, default is position:'relative' */
        boxShadow: 'none',
        WebkitBoxShadow:"none",
        MozBoxShadow:"none",
    },
    disabled :{
        backgroundColor: 'inherit !important'
    }
};

const EXPanelSummaryStyles = {
    root: {
        padding: 0,
        backgroundColor: '#fffcdc'
    },
    
    /*
    content: {
        "& > :last-child": {
            paddingRight: 100
        },
        paddingLeft: 0,
    },
    */
    content: {
        margin: "4px 0",
            "&$expanded": {
            margin: "4px 0"
        }
    },
    expandIcon: {
        top: "100%"
    },
    expanded: {
        backgroundColor: 'inherit'
    },
    disabled:{
        opacity: '1 !important',
        backgroundColor: 'inherit'
    }
};

const EXPanelDetailStyles = {
    root: {
        padding: 0
    }
};

const EXPanel = withStyles(EXPanelStyles)(ExpansionPanel);
EXPanel.muiName = "ExpansionPanel";

const EXPanelSummary = withStyles(EXPanelSummaryStyles)(ExpansionPanelSummary);
EXPanelSummary.muiName = "ExpansionPanelSummary";

const EXPanelDetails = withStyles(EXPanelDetailStyles)(ExpansionPanelDetails);
EXPanelDetails.muiName = "ExpansionPanelDetails";

const EventItemView = ({ event, classes }) => {

    const [expanded, setExpand] = useState(false);
    const hasNewlines = !event.note || (event.note.match(/<br>|<\/br>/) || '').length === 0 ? false : true;
    const toolTip = hasNewlines ? event.note.replace(/<br>/g," ") : event.note;
    
    const toggleExpand = (event, expanded) => {
        setExpand(expanded);
    };

    return (
        <ListItem>
            <ListItemAvatar className={classes.list_avatar}>
                <Avatar user={event.user_info} />
            </ListItemAvatar>
            <ListItemText
                primary={
                    <div className={classes.truncate}>
                        <strong>
                            {event.user_info ? event.user_info.name : 'Anonymous'}
                        </strong>{' '}
                        {event.subject}
                    </div>
                }
                secondary={
                    <div className={classes.root}>
                        <div>{new Date(event.add_time).toLocaleString()}</div>
                        <br/>
                        {event.note &&
                        <EXPanel 
                            disabled = {!hasNewlines ? true : false} 
                            onChange={toggleExpand}>
                            {hasNewlines && 
                            <EXPanelSummary expandIcon={<ExpandMoreIcon />}>
                                {!expanded &&
                                <Typography 
                                    component="div"
                                    className={classes.tooltip}
                                    style={{br:{content: ''}}}
                                    dangerouslySetInnerHTML={{__html: toolTip}}>
                                </Typography>
                                }
                            </EXPanelSummary>
                            }
                            <EXPanelDetails>
                                <div dangerouslySetInnerHTML={{__html: event.note}} />
                            </EXPanelDetails>
                        </EXPanel>
                        }
                    </div>
                }
            />
        </ListItem>
    );
}

const EventItem = withStyles(styles)(EventItemView);

export default EventItem;