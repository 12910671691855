import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNotify } from 'react-admin';
import { Loading, Error } from 'react-admin';

import api from '../utils/api';
import config from 'config';

import {
	Button,
	Card,
	CardContent,
	CardHeader,
	TextField as MUITextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		/* the parent card set overflow causes datepicker is hiddern behind */
		overflow: "unset",
	},
	widgetHeader: {
		backgroundColor: theme.widget.headerBackgroundColor,
		padding: 7,
		color: theme.widget.headerColor,
        fontWeight: 600,
	},
	item: {
		maxWidth: "610px"
	}
}));

export const Email = (props) => { 
	const {header = true, headerTitle = "Settings"} = props;

    const classes = useStyles();
	const notify = useNotify();

	const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

	const [subject, setSubject] = useState("");
	const [content, setContent] = useState("");
	const templateId = useRef(null);

	const fetchData = useCallback(async () => {
		try{
			const emailTemplate = await api('get', `${config.app.back_end.url}/EmailTemplate/sign-in-end-visit-parent-survey`);
			const {subject, content, id} = emailTemplate.data;
			setSubject(subject);
			setContent(content);
			templateId.current = id;
			setLoaded(true);
		}
		catch(err){
			setError(true);
			notify(`Could not get email template: ${err}`, 'error');
		}
	}, [])

	useEffect(() => {
		fetchData();
	}, [fetchData])

	const handleUpdate = async () => {
		try{
			await api('patch', `${config.app.back_end.url}/EmailTemplate/${templateId.current}`, {subject, content});
		}
		catch(err){
			notify(`Could not update email template: ${err}`, 'error');
		}
	}

	if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }

    return (
        <Card className={classes.root}>
            {header && <CardHeader
				title={headerTitle}
				className={classes.widgetHeader}
				titleTypographyProps={{ variant: "subtitle1", fontWeight:'bold' }}
			></CardHeader>}
            <CardContent>
                <div
                    style={{ 
                        display: "flex",
						flexDirection: "column",
                        rowGap: "28px",
                    }}
                >	
					<div>
						<MUITextField
							className={classes.item}
							required={true}
							fullWidth
            				multiline
							name="caption"
							label="Subject"
							value={subject}
							onChange={(e) => setSubject(e.target.value)}
						/>
                    </div>
                    <div>
						<MUITextField
							className={classes.item}
							required={true}
							fullWidth
            				multiline
							variant="outlined"
							placeholder="supported HTML"
							name="content"
							label="Content"
							value={content}
							onChange={(e) => setContent(e.target.value)}
							InputLabelProps={{
								shrink: true,
							}}
						/>
                    </div>
					<div>
                        <Button
                            variant="outlined" color="primary"
                            style={{textTransform: 'none', maxWidth:"70px"}}
							disabled={!subject || !content}
                            onClick={handleUpdate}
                        >Update
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}