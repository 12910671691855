import { stringify } from 'query-string';
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'ra-core';

import config from 'config';
import httpClientToken from './utils/httpClient';

import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha512';

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/user?field=id,name&query=id = 1 OR user_name = 'test'&order_by=name&offset=0&count=10
 *
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
const dataProvider =  (apiUrl = config.app.back_end.url, httpClient = httpClientToken) => {
    let BOOKING_JSON_STATUS_MAPPING = config.booking.BOOKING_JSON_STATUS_MAPPING,
        BOOKING_JSON_UPDATE_FIELD = config.booking.BOOKING_JSON_UPDATE_FIELD,
        BOOKING_JSON_SURVEY_FEEDBACK_UPDATE_FIELD = config.booking.BOOKING_JSON_SURVEY_FEEDBACK_UPDATE_FIELD;
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = '', arrQuery = [],
            select = null, objQuery = {};
        const options = {};
        
        switch (type) {
            case GET_LIST:
                const { page, perPage } = params && params.pagination ? params.pagination : {};
                const { field, order } = params && params.sort ? params.sort : {};
                if(resource.toLowerCase() === "group"){
                    select = "id, name";
                    for(let key in params && params.filter)
                        arrQuery.push(`${key} LIKE '${params.filter[key]}'`);
                }
                else if(resource.toLowerCase() === "user"){
                    select = "id, user_name, first_name, last_name, email, user_group, activity_on";
                    for(let key in params.filter)
                        arrQuery.push(`${key} LIKE '${params.filter[key]}'`);
                    /* only get active user */
                    arrQuery.push(`status = 1`);
                }
                else if(resource.toLowerCase() === "appointment"){
                    select = "id, centre_email, name, code, pipeline_id, stage_id, person_id, deal_id, deal_status, time_slot, salutation, parent_name, child_name, date_of_birth, mobile_no, contact_email, program_interested, who_follow_up, walk_in, status";
                    objQuery.list_booking = true;
                    /* format filter: sql condition */
                    const eq_filter = {
                        who_follow_up: true, 
                        centre_email: true, 
                        program_interested: true,
                        deal_status: true,
                    };
                    const in_filter = {
                        status: true
                    };
                    let op_comp = null,
                        value = null;

                    for(let key in params.filter){
                        if(params.filter[key] === "none") continue;

                        /* filter time slot range */
                        if(key === "time_slot_from"){
                            op_comp = ">=";
                            value = params.filter[key] + " 00:00:00";
                            value = `"${value}"`;
                            key = "time_slot";
                        }
                        else if(key === "time_slot_to"){
                            op_comp = "<=";
                            value = params.filter[key] + "23:59:59";
                            value = `"${value}"`;
                            key = "time_slot";
                        }
                        /* other filters */
                        else if(eq_filter[key]){
                            op_comp = "=";
                            value = isNaN(params.filter[key]) ? params.filter[key].toLowerCase() : params.filter[key];
                            value = `"${value}"`;
                        }
                        else if(in_filter[key]){
                            op_comp = "IN";
                            value = `(${params.filter[key]})`;
                        }
                        else{
                            op_comp = "LIKE";
                            value = isNaN(params.filter[key]) ? params.filter[key].toLowerCase() : params.filter[key];
                            value = `"${value}"`;
                        }
                        arrQuery.push(`${key} ${op_comp} ${value}`);
                    }
                }
                else if(resource.toLowerCase() === "centre"){ // WORKING HEEW RY TO CHANGE ID TO EMAIL.
                }
                else if(resource.toLowerCase().includes("vmsactivity")){
                    resource = `${resource}/${params.filter.uri}`;
                    select = "id, user_id, done, type, add_time, person_id, deal_id, update_time, update_user_id, note";
                    objQuery.centre_email = params.filter.centre_email;
                }

                if(select) objQuery.field = select;
                if(page && perPage){
                    objQuery.offset = (page - 1) * perPage;
                    objQuery.count = perPage;
                }
                if(order)
                    objQuery.order_by = `${field}  ${order}`;
                if(arrQuery.length) objQuery.query = arrQuery.join(" AND ");
                url = `${apiUrl}/${resource}?${stringify(objQuery)}`;
                break;
            case GET_ONE:
                if(resource.toLowerCase() === "appointment")
                    select = "id, centre_email, code, pipeline_id, stage_id, person_id, deal_id, time_slot, salutation, parent_name, child_name, date_of_birth, mobile_no, contact_email, program_interested, walk_in, status, who_follow_up";
                else if(resource.toLowerCase() === "user"){
                    select = "id, user_name, first_name, last_name, email, user_group, activity_on";
                    arrQuery.push(`status = 1`);
                }
                
                objQuery = {
                    field: select
                };
                if(arrQuery.length) objQuery.query = arrQuery.join(" AND ");

                url = `${apiUrl}/${resource}/${params.id}?${stringify(objQuery)}`;
                break;
            case GET_MANY: {
                if(resource.toLowerCase() === "centre"){
                    /* name and email fields will be used in booking.js file in
                     * <SelectInput optionText="name" optionValue="email" />
                    */
                    objQuery.field = "name, email";
                    objQuery.query = `email IN (${params.ids.join(",")})`;
                }
                else if(resource.toLowerCase() === "group"){
                    objQuery.field = "id, name";
                    objQuery.query = `id IN (${params.ids.join(",")})`;
                }
                url = `${apiUrl}/${resource}?${stringify(objQuery)}`;
               
                break;
            }
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                objQuery = {
                    sort: JSON.stringify([field, order]),
                    range: JSON.stringify([
                        (page - 1) * perPage,
                        page * perPage - 1,
                    ]),
                    filter: JSON.stringify({
                        ...params.filter,
                        [params.target]: params.id,
                    }),
                };
                url = `${apiUrl}/${resource}?${stringify(objQuery)}`;
                break;
            }
            case UPDATE:
                let objPatch = {};
                if(resource.toLowerCase() === "appointment"){
                    /* get changed fields only */
                    for(let key in params.data){
                        if(params.data[key] && (params.previousData[key] !== params.data[key])){
                            if(key === "status"){
                                let status_no = Object.keys(BOOKING_JSON_STATUS_MAPPING).find(status => BOOKING_JSON_STATUS_MAPPING[status] === params.data[key]);
                                if(status_no !== params.previousData[key])
                                    objPatch[key] = status_no;
                            }
                            else if(key === "walk_in"){
                                let walkin_no = params.data[key] ? 1 : 0;
                                if(walkin_no !== params.previousData[key])
                                    objPatch[key] = walkin_no;
                            }
                            else
                                objPatch[key] = params.data[key];
                        }
                    }
                    /* build json, json_survey_feedback and other fields */
                    for(let key in objPatch){
                        if(BOOKING_JSON_UPDATE_FIELD[key] === true){
                            if(!objQuery.json) objQuery.json = {};
                            objQuery.json[key] = objPatch[key];
                        }
                        else if(BOOKING_JSON_SURVEY_FEEDBACK_UPDATE_FIELD[key] === true){
                            if(!objQuery.json_survey_feedback) objQuery.json_survey_feedback = {};
                            objQuery.json_survey_feedback[key] = objPatch[key];
                        }
                        else objQuery[key] = objPatch[key];
                    }
                    if(objQuery.json) objQuery.json = JSON.stringify(objQuery.json);
                    if(objQuery.json_survey_feedback) objQuery.json_survey_feedback = JSON.stringify(objQuery.json_survey_feedback);

                    /* add additional fields needed by backend API */
                    objQuery.centre_email = params.data.centre_email;
                    objQuery.person_id = params.data.person_id;
                    objQuery.deal_id = params.data.deal_id;
                }
                else if(resource.toLowerCase() === "user"){
                     /* get changed fields only */
                     for(let key in params.data){
                        if(params.data[key] != params.previousData[key]){
                            if(key === "user_group"){
                                if(params.data[key])
                                    objQuery[key] = Array.isArray(params.data[key]) ? `[${params.data[key].join(',')}]` :  `[${params.data[key]}]`;
                                else objQuery[key] = null;
                            }
                            else objQuery[key] = params.data[key];
                            if(Array.isArray(objQuery[key])) objQuery[key] = JSON.stringify(objQuery[key]);
                        }
                        if(key === "password")
                            objQuery["new_password"] = ""+sha256(""+sha512(params.data[key]));
                        else if(key === "activity_on" && params.data[key]){
                            if(params.data[key].length)
                                objQuery[key] = `{"centre_email":[${'"' + params.data[key].join('","') + '"'}]}`;
                            else objQuery[key] = null;
                        }
                    }
                    if(objQuery.password) delete objQuery.password;
                }
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'PATCH';
                options.body = JSON.stringify(objQuery);
                break;
            case CREATE:
                if(params.data && params.data.uri){
                    resource = `${resource}/${params.data.uri}`;
                    delete params.data.uri; 
                }
                url = `${apiUrl}/${resource}`;
            
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                if(resource.toLowerCase() === "user")
                    objQuery = {logical_delete: false};
                
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                options.body = JSON.stringify(objQuery);
                break;
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { headers, json } = response;

        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                /* ignore this header prop because we might use lazy loading */
                if (!headers.has('content-range')) {
                    throw new Error(
                        'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
                    );
                }
                if(resource.toLowerCase() === "appointment"){
                    /* data transformation */
                    for(let i = 0, len = json.data.length; i < len; i++){
                        /* transform json field to seperated fields 
                         * if(resource.toLowerCase() === "appointment" && json.data[0].json)
                        */
                        /* no need any more because back-end support to select json field
                        let objJSON = JSON.parse(json.data[i].json);
                        for(let key in objJSON){
                            json.data[i][key] = objJSON[key];
                        }
                        delete json.data[i].json;
                        */
                        /* transform walk_in, status and who_follow_up */
                        json.data[i].walk_in = json.data[i].walk_in === 0 ? false : true;
                        json.data[i].status = BOOKING_JSON_STATUS_MAPPING[json.data[i].status];
                        if(json.data[i].who_follow_up == null)
                            json.data[i].who_follow_up = "";
                        
                    }
                }
                else if(resource.toLowerCase().includes("vmsactivity")){
                    /* data transformation */
                    let user_id = null;
                    for(let i = 0, len = json.data.length; i < len; i++){
                        /* replace user_id with user_info struct */
                        user_id = json.data[i].user_id;
                        json.data[i].user_info = {id: user_id, name: json.activity_distribution[user_id].name};
                        delete json.data[i].user_id;
                    }
                }
                else if(resource.toLowerCase() === "user"){
                    for(let i = 0, len = json.data.length; i < len; i++){
                        if(json.data[i].activity_on)
                            json.data[i].activity_on = JSON.parse(json.data[i].activity_on).centre_email;
                        if(json.data[i].user_group)
                            json.data[i].user_group = JSON.parse(json.data[i].user_group);
                    }
                }
                return {
                    data: json.data,
                    total: parseInt(
                        headers
                            .get('content-range')
                            .split('/')
                            .pop(),
                        10
                    ),
                };
            case GET_MANY: {
                /* Booking list uses centre_email to make a reference to centre name in Centre.
                 * However, the Centre's id column does not store email (store in email column)
                 * so need to add an additional column id to store email to make the reference works.
                */
                if(resource.toLowerCase() === "centre"){
                    for(let i = 0, len = json.data.length; i < len; i++)
                        json.data[i].id = json.data[i].email;
                }
                else if(resource.toLowerCase() === "user"){
                    for(let i = 0, len = json.data.length; i < len; i++){
                        if(json.data[i].activity_on)
                            json.data[i].activity_on = JSON.parse(json.data[i].activity_on).centre_email;
                        if(json.data[i].user_group)
                            json.data[i].user_group = JSON.parse(json.data[i].user_group);
                    }
                }
                return {
                    data: json.data,
                };
            }
            case GET_ONE:{
                /* user_group need to be converted into array to feed into SelectInput in UserEdit */
                if(resource.toLowerCase() === "user"){
                    if(json.data.activity_on) json.data.activity_on = JSON.parse(json.data.activity_on).centre_email;
                    if(json.data.user_group) json.data.user_group = JSON.parse(json.data.user_group);
                }
                    
                return {
                    data: json.data,
                };
            }
            case CREATE:
                let objRet = {id: json.id};
                /* handle for User resource */
                if((resource.toLowerCase() === "user") && json.data && json.data.user_id)
                    objRet.user_id = json.data.user_id;

                return { data: { ...params.data, ...objRet } };
            case UPDATE:
                return { data: json.data };
            case DELETE_MANY: {
                return { data: json || [] };
            }
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY && Array.isArray(params.ids)) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY  && Array.isArray(params.ids)) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        
        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
};

export default dataProvider;