import React, { Component, Fragment } from 'react';
import { Title, GET_LIST, fetchStart, fetchEnd } from 'react-admin';
import { parse } from "query-string";

import dataProvider from '../../dataProvider';
import TimelineLoaded from './TimelineLoaded';
import TimelineLoading from './TimelineLoading';
import TimelineEmpty from './TimelineEmpty'
import EventCreateButton from './EventCreateButton';

import config from 'config';

export class Timeline extends Component {
    page = 1;
    messagesEndRef = React.createRef()
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            ids: [],
            latestId: null,
            total: null,
            loadedOnce: false
        };
       
        let params = parse(props.location.search);
        this.deal_id = params.deal_id;
        this.centre_email = params.centre_email;
        /* use localStorage to handle refresh page */
        if(props.location.obj_data) localStorage.setItem('obj_data', JSON.stringify(props.location.obj_data));
        this.obj_data = JSON.parse(localStorage.getItem('obj_data'));
    }

    updateData = (perPage) => {

        fetchStart();

        dataProvider()(GET_LIST, "VMSActivity", {
            pagination: {page: this.page, perPage: !perPage ? config.timeline.PREDICTABLE_RETURNED_RECORDS: perPage},
            filter:{uri: `deals/${this.deal_id}`, centre_email: this.centre_email},
            sort: {field: 'id', order: 'ASC'}
        })
        .then((res) => {
            const data = Object.values(res.data),
                  ids = Object.keys(res.data);
            this.setState({
                events: this.page > 1 ? this.state.events.concat(data) : data, 
                ids: this.page > 1 ? this.state.ids.concat(ids) : ids ,
                total: this.page > 1 ? this.state.total + res.total : res.total,
                latestId: res.data[res.data.length - 1].id,
                loadedOnce: true
            });
           
        })
        .catch(error => {
        })
        .finally(() => {
            fetchEnd();
        })
    };

    handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) 
            return;
        this.handleLoadMore();
        this.scrollToBottom();
    }

    async componentDidMount() {
        await this.updateData();

        if(config.timeline.SCROLL_LOAD_MORE)
            window.addEventListener('scroll', this.handleScroll);
        else
            this.scrollToBottom();
    }

    componentWillUnmount() {
        this.page = 1;
        if(config.timeline.SCROLL_LOAD_MORE)
            window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        if(!config.timeline.SCROLL_LOAD_MORE)
            this.scrollToBottom();
    }

    scrollToBottom() {
        if(!this.el) return;
        this.el.scrollIntoView({ behavior: 'smooth' });
    }

    handleLoadMore = () => {
        this.page = this.page + 1;
        this.updateData();
    }

    handleLoadAll = () => {
        const { events, total } = this.state;
        this.updateData(Math.max(events.length, total) + 1);
    }

    render() {
        const { events, total, loadedOnce } = this.state;
 
        return (
            <Fragment>
                <Title title="Events" />
                {!loadedOnce ? (
                    <TimelineLoading />
                ) : events.length === 0 ? (
                    <TimelineEmpty />
                ) : (
                    <Fragment>
                    <TimelineLoaded
                        events={events}
                        total={total}
                        scrollLoadMore={config.timeline.SCROLL_LOAD_MORE}
                        handleLoadMore={this.handleLoadMore}
                    />
                    <EventCreateButton
                        obj_data={this.obj_data}
                        handleLoadAll={this.handleLoadAll}
                    />
                    <div ref={el => { this.el = el; }} />
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Timeline;