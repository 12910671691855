import createTheme from '@material-ui/core/styles/createTheme';
import red from '@material-ui/core/colors/red';

const Theme = createTheme();

Theme.palette = {
    type: 'light',
    primary: {
        main:  'rgba(25, 118, 210, 1)',
    },
    secondary: {
        main:  'rgba(25, 118, 210, 1)',
    },
    error: red,
};
Theme.shape = {
    borderRadius: 4
};
Theme.sidebar = {
    width: 127, // The default value is 240
    closedWidth: 0, // The default value is 55
};

Theme.widget = {
    headerBackgroundColor: 'rgba(25, 118, 210, 0.8)',
    headerColor: '#ffffff',
};

Theme.overrides = {
    MuiAppBar: {
        colorSecondary: {
            backgroundColor:  'rgba(25, 118, 210, 1)',
        }
    },
    RaToolbar: {
        toolbar: {
            backgroundColor: "transparent",
        }
    },
    MuiButton: { // override the styles of all instances of this component
        root: { // Name of the rule
            minWidth: 'unset', // Some CSS
        },
    },
    /* ref: https://codesandbox.io/s/j65nlor1v?file=/src/index.js:835-839 */
    MuiFilledInput: {
        root: {
            //border: "1px solid #e2e2e1",
            overflow: "hidden",
            borderRadius: 4,
            backgroundColor: "transparent", // "#fcfcfb"
            transition: Theme.transitions.create(["border-color"/*, "box-shadow"*/]),
            "&:hover": {
                backgroundColor: "transparent", // : "#fff"
            },
            "&$focused": {
                backgroundColor: "transparent", // : "#fff"
                // boxShadow: `${fade(Theme.palette.primary.main, 0.25)} 0 0 0 2px`,
                borderColor: Theme.palette.primary.main
            }
        }
    }
};

export default Theme;