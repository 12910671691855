import React, { useEffect, useState, useCallback } from 'react';
import { useNotify, Loading, Error } from 'react-admin';

import {
	FormControl,
	Button,
	Card,
	CardContent,
	CardHeader,
	InputLabel,
	Select, MenuItem,
    Chip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import SliderTimeSlot from '../Slider/TimeSlot';

import api from '../../utils/api';
import config from 'config';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		/* the parent card set overflow causes datepicker is hiddern behind */
		overflow: "unset",
	},
    row: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        columnGap: "16px",
    },
    gap: {
        columnGap: "28px",
    },
    item: {
        minWidth: "280px"
    },
	widgetHeader: {
		backgroundColor: theme.widget.headerBackgroundColor,
		padding: 7,
		color: theme.widget.headerColor,
        fontWeight: 600,
	},
    chip: {
        marginTop: "28px",
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        columnGap: "4px",
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

export const Slot = (/*props*/) => { 
    const classes = useStyles();
	const notify = useNotify();

	const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

    const [openingTime, setOpeningTime] = useState(0);
    const [closingTime, setClosingTime] = useState(0);
    const [closingDates, setClosingDate] = useState([]);
    const [centres, setCentre] = useState([]);
    const [selectedCentre, setSelectedCentre] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

	const fetchData = useCallback(async () => {
		try{
            const _centres = await api('get', `${config.app.back_end.url}/Centre?field=id,email,name&order_by=name ASC`);
			setCentre(_centres.data);
            setLoaded(true);
		}
		catch(e){
			setError(true);
		}
		
	}, [])

	useEffect(() => {
		fetchData();
	}, [fetchData])

    const handleCentreChange = async (e) => {
        const value = e.target.value;
        setSelectedCentre(value);

        const centreInfo = await api('get', `${config.app.back_end.url}/Centre/${value}?field=id,email,closing_date,opening_time,closing_time`);
     
        if(centreInfo.data.closing_date)
            setClosingDate(JSON.parse(centreInfo.data.closing_date));
        else
            setClosingDate([]);
     
        setOpeningTime(centreInfo.data.opening_time);
        setClosingTime(centreInfo.data.closing_time)
	}

    const handleOnChangeOpeningTime = (e, value) => {
        setOpeningTime(value);
    }
    
    const handleOnChangeClosingTime = (e, value) => {
        setClosingTime(value);
    }

	const handleDeleteBlockingSlot = async (closing_date) => {
        setClosingDate(closingDates.filter(_closing_date => (_closing_date !== closing_date)));
	}

    const handleOnChangeClosingDate = async (date) => {
        if(date){
            setSelectedDate(date);
            const closing_date = date.toISOString().split('T')[0];
            if(closingDates.includes(closing_date)) return;
            setClosingDate([...closingDates, closing_date]);
        }
        else setSelectedDate("")
    }

    const handleSave = async () => {
		try{
            if(!selectedCentre) throw "missing centre";
            await api('patch', `${config.app.back_end.url}/Centre/${selectedCentre}`, 
                {   closing_date: JSON.stringify(closingDates),
                    opening_time: openingTime,
                    closing_time: closingTime
                }
            );
            notify(`Saved data successfully`, 'success');
		}
		catch(err){
			notify(`Could not block date: ${err}`, 'error');
		}
    }

    const handleImport = async () => {
        try{
            await api('post', `${config.app.back_end.url}/Centre/import`);
        }
        catch(err){
            notify(`Failed to import: ${err}`, 'error');
        }
    }

	if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }

    return (
        <Card className={classes.root}>
            <CardHeader
				title={`Opening time & Blocking dates`}
				className={classes.widgetHeader}
				titleTypographyProps={{ variant: "subtitle1", fontWeight:'bold' }}
			></CardHeader>
            <CardContent>
                <div className={`${classes.row} ${classes.gap}`}>
                    <div>
						<FormControl required>
							<InputLabel shrink={!!selectedCentre}>Select centre</InputLabel>
							<Select
								style={{minWidth: 127}}
								name="centre-label"
								id="centre"
								displayEmpty
								value={selectedCentre}
								onChange={handleCentreChange}
								>
								{centres.map(item => (
									<MenuItem key={item.email} value={item.email}>{item.name}</MenuItem>
								))}
							</Select>
						</FormControl>
                    </div>
                    <div className={classes.item}>
                        <SliderTimeSlot
                            label={"Opening time"}
                            name={"open_time"}
                            val={openingTime}
                            onChange={handleOnChangeOpeningTime}
                        />
                    </div>
                    <div className={classes.item}>
                        <SliderTimeSlot
                            label={"Closing time"}
                            name={"close_time"}
                            val={closingTime}
                            onChange={handleOnChangeClosingTime}
                        />
                    </div>
                    <div>
                        <DateTimePicker
							inputStyle = {{minWidth: "250px"}}
							name="closing_date"
							label="Select date to block"
                            selected={selectedDate}
                            includeTime={true}
                            minDate={new Date()}
                            excludeDates={closingDates.map(closing_date => new Date(closing_date))}
                            onChange={(date) => handleOnChangeClosingDate(date)}
							isClearable
                        />
                    </div>
                    <div>
                        <Button
                            variant="outlined" color="primary"
                            style={{textTransform: 'none'}}
                            onClick={()=>handleSave()}
                        >Save
                        </Button>
                    </div>
                    <div style={{display: "none"}}>
                        <Button
                            variant="outlined" color="primary"
                            style={{textTransform: 'none'}}
                            onClick={()=>handleImport()}
                        >Import
                        </Button>
                    </div>
                </div>
                <div className={classes.chip}>
                    {closingDates.map(closing_date => (
                        <Chip 
                            color="primary"
                            key={closing_date}
                            label={closing_date}
                            onDelete={() => handleDeleteBlockingSlot(closing_date)} 
                        />
                    ))}
                </div>
            </CardContent>
        </Card>
    )
}