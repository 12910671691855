import React, { Fragment }  from 'react';
import { useRefresh,
         List, Edit, Datagrid, 
         ReferenceArrayField, SingleFieldList,
         TextField, ChipField,
         TextInput, PasswordInput, SelectInput, SelectArrayInput, SimpleForm,  
         SaveButton, EditButton, DeleteButton,
         regex
          } from 'react-admin';

import Toolbar from '@material-ui/core/Toolbar';
import { useQueryWithStore, Loading, Error } from 'react-admin';

import UserCreateButton from '../components/User/UserCreateButton';

/* Remove the export action in an list view */
const UserActions = (/*{
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    hasCreate,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total, 
    ...props // props.data will contain all records of current listing
}*/) =>{
    return(
        /* NOTE: If we use react-admin Toolbar will cause resource is null when pass to provider.
         * Then export function will be crashed
        */
        <Toolbar>
           <UserCreateButton 
            refresh={useRefresh()}
           />
        </Toolbar>
    );
};

/* Remove the <DeleteButton> from the toolbar in an edit view */
const UserEditToolbar = props => {
    return (
        <Toolbar {...props} >
            <SaveButton {...props} />
        </Toolbar>
    )
};


export const UserList = props => {   
    return (
        <Fragment>
        <List {...props}
            actions={<UserActions />}
            bulkActionButtons={false} 
        >
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="user_name" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <ReferenceArrayField label="Role" source="user_group" reference="Group" sortable={false}>
                    <SingleFieldList linkType={false} >
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField label="Permission on" source="activity_on" reference="Centre" target="email">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <EditButton />
                <DeleteButton  />
            </Datagrid>
        </List>
        </Fragment>
    )
};

const validatePassword = regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/, 'The password must be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character');

/* access the current record in admin on Edit is impossible
 * except to create a custom Field component because it will
 * receive record as default. Or create a component (WithProps) to add this behaviour
*/
const WithProps = ({children,...props}) => children(props);


export const UserEdit = props => {

    let loaded, error, groups, centres;
    ({ loaded, error, data: groups } = useQueryWithStore({
        type: 'GET_LIST',
        resource: 'Group',
    }));

    /* get centre list */
    ({ loaded, error, data: centres } = useQueryWithStore({
        type: 'GET_LIST',
        resource: 'Centre',
    }));

    if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if(!groups || !centres) return null;

    /* need to transform user_group to array otherwise after saving will got error
     * ids.map is not function. The error happen when user has no role then update a role
     * then `ReferenceArrayField label="Role" source="user_group"...` in UserList need an array of id
     * to fetch data
    */
    const transform = data => {
        return{
            ...data,
            user_group: [data.user_group]
        }
    };

    return (
        <Edit {...props} 
            transform={transform}
            title="Edit user">
            <WithProps>{({record,...props})=> {
                return (
                    <SimpleForm record={record} {...props} toolbar={<UserEditToolbar />}>
                        <TextInput source="user_name" />
                        <TextInput source="first_name" />
                        <TextInput source="last_name" />
                        <PasswordInput source="password" validate={validatePassword} />
                        {record.user_group && record.user_group.length > 1
                        ?(<SelectArrayInput 
                            label="Role"
                            source="user_group"
                            choices={groups}
                        />)
                        :(<SelectInput
                            label="Role"
                            source="user_group"
                            choices={groups}
                        />)}
                         <SelectArrayInput
                            optionValue="email"
                            optionText="name"
                            source="activity_on"
                            label="Permission on"
                            choices={centres}
                        />
                    </SimpleForm>
                )
            }}
             </WithProps>
        </Edit>
    );
}