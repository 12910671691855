import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNotify,  ListContextProvider, 
		 Datagrid, Pagination, TextField, BooleanField  } from 'react-admin';
import { Loading, Error } from 'react-admin';
import { stringify } from 'query-string';

import {
	FormControl,
	FormControlLabel,
	Button,
	Card,
	CardContent,
	CardHeader,
	InputLabel,
	TextField as MUITextField, Select, MenuItem, Checkbox
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import HTMLElement from '../components/Field/HTMLElement';

import api from '../utils/api';
import config from 'config';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		/* the parent card set overflow causes datepicker is hiddern behind */
		overflow: "unset",
	},
	widgetHeader: {
		backgroundColor: theme.widget.headerBackgroundColor,
		padding: 7,
		color: theme.widget.headerColor,
        fontWeight: 600,
	},
}));

export const Field = (props) => { 
	const {header = true, headerTitle = "Elements"} = props;

    const classes = useStyles();
	const notify = useNotify();

	const [data, setData] = useState([]);
	const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

	const [element, setElement] = useState("");
	const [name, setName] = useState("");
	const [caption, setCaption] = useState("");
	const [value, setValue] = useState("");
	const [order, setOrder] = useState("");
	const [required, setRequired] = useState(false);
	const [mode, setMode] = useState("ADD");

	const editingId = useRef(null);
	const elementArray = useRef([
		{ key: "label", value: "Label" },
		{ key: "input", value: "Input" },
		{ key: "checkbox", value: "Check" },
		//{ key: "select", value: "Select" },
		{ key: "radio", value: "Choice" },
	]);
	/*
		x: required
					Title	Value
		label:				x
		input		
		checkbox			x
		select				x
	*/
	/* to simplify UX only add title */
	const elementMask = useRef({
		label:["value"],
		input:[],
		checkbox:["caption", "value"],
		select:["value"],
		radio:["caption", "value"]
	});

	const total = useRef(0);
	const [sort, setSort] = useState({ field: 'display_order', order: 'ASC' });
	const [page, setPage] = useState(1);
  	const [perPage, setPerPage] = useState(10);

	const isRequired = (component, prop) => (elementMask.current[component].includes(prop) ? true : false);
	const disabled = () => {
		const required = !!element && !!name;
		const objMask = elementMask.current[element] ? elementMask.current[element] : [];
		for(const prop of objMask){
			if(prop === "value") return !(required && !!value);
			else if(prop === "caption") return !(required && !!caption);
		}
		return !required;
	}

	const fetchData = useCallback(async () => {
		try{
			/* build sort and paging */
			const queries = {}
			queries.offset = (page - 1) * perPage;
			queries.count = perPage;
			queries.order_by = `${sort.field}  ${sort.order}`
			/* later can set filter to select for a particular centre and/or form id */
			/*if(centre !== "all") queries.query = `centre=${centre}`;*/
		
			const fields = await api('get', `${config.app.back_end.url}/Field?${stringify(queries)}`);
			total.current = fields.range.total;
			setData(fields.data);
			setLoaded(true);
		}
		catch(e){
			setError(true);
		}
		
	}, [sort, page, perPage])

	useEffect(() => {
		fetchData();
	}, [fetchData])

	const reset = () => {
		setElement("");
		setName("");
		setCaption("");
		setValue("");
		setOrder("");
		setRequired(false);
	}

	const handleNameChange = (e) => {
		setName(e.target.value);
	}

	const handleElementChange = (e) => {
		reset();
		setElement(e.target.value);
	}

	const handleCaptionChange = (e) => {
        setCaption(e.target.value);
    }

	const handleValueChange = (e) => {
        setValue(e.target.value);
    }

	const handleOrderChange = (e) => {
		setOrder(e.target.value.replace(/[^1-9]/g, ''));
	}

	const handleRequiredChange = (e) => {
		setRequired(e.target.checked);
	}

    const handleAddUpdateElement = async () => {
		try{
			const req = {
				/*centre: centre,*/
				component: element,
				name: name,
				caption: caption,
				value: value,
				display_order: order,
				required: required ? 1 : 0
			};
			if(mode === "ADD"){
				const response = await api('post', `${config.app.back_end.url}/Field`, req);
				const _data = data.map(_item => ({..._item}));
				_data.push(response.data);
				total.current += 1;
				setData(_data);
			}
			else if(mode === "EDIT"){
				if(!editingId) throw "invalid element id";
				const response = await api('patch', `${config.app.back_end.url}/Field/${editingId.current}`, req);
				const idx = data.findIndex(_item => _item.id === editingId.current);
				if(idx === -1) throw "could not find id";
				const _data = data.map(_item => ({..._item}));
				_data[idx] = response.data;
				_data.sort((a, b) => (a.display_order - b.display_order));
				setData(_data);
			}
			else throw "invalid operation";

			reset();
		}
		catch(err){
			notify(`Could not add/edit new element: ${err}`, 'error');
		}
    }

	const handleEditElement = record => {
		const {id, component, name, caption, value, display_order, required} = record;
		setMode("EDIT");
		setElement(component);
		setName(name);
		setCaption(caption||"");
		setValue(value||"");
		setOrder(display_order||"");
		setRequired(required);
		editingId.current = id;
	}

    const handleDeleteElement = async record => {
		try{
			await api('delete', `${config.app.back_end.url}/Field/${record.id}`);
			let _data = data.filter(item => (item.id !== record.id));
			total.current -= 1;
			setData(_data);
		}
		catch(err){
			notify(`Could not delete the element: ${err}`, 'error');
		}
    }

	const EditElementButton = ({ record }) => {
		return(
			<Button
				onClick={()=>{handleEditElement(record)}}
			><EditIcon />
			</Button>
		);
	}

	const DeleteElementButton = ({ record }) => {
		return(
			<Button
				onClick={()=>{handleDeleteElement(record)}}
			><DeleteIcon />
			</Button>
		);
	}
	
	const TextFieldComponent = ({record}) => {
		return(
			<div>{elementArray.current.find(obj=>obj.key === record.component).value}</div>
		)
	}

	const TextFieldRequired = ({record}) => {
		const _record = {...record, isRequired: record.required ? true : false};
		return(
			<BooleanField record={_record} source="isRequired" label="Required"/>
		)
	}

	if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if(!data) return null;

    return (
        <Card className={classes.root}>
            {header && <CardHeader
				title={headerTitle}
				className={classes.widgetHeader}
				titleTypographyProps={{ variant: "subtitle1", fontWeight:'bold' }}
			></CardHeader>}
            <CardContent>
                <div
                    style={{ 
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        columnGap: "16px",
                    }}
                >
					<div>
						<FormControl required>
							<InputLabel shrink={!!element}>Select an element</InputLabel>
							<Select
								style={{minWidth: 160}}
								name="element-label"
								id="element"
								value={element}
								displayEmpty
								onChange={handleElementChange}
							>
								{elementArray.current.map(item => (
									<MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>
								))}
							</Select>
						</FormControl>
                    </div>
					<div>
						<MUITextField
							required
							fullWidth
            				multiline
							name="name"
							label="Name"
							value={name}
							onChange={handleNameChange}
						/>
                    </div>
					<div>
						<MUITextField
							required={element && isRequired(element, "caption")}
							fullWidth
            				multiline
							name="caption"
							label="Title"
							value={caption}
							onChange={handleCaptionChange}
						/>
                    </div>
                    <div>
						<MUITextField
							style={{minWidth: "340px"}}
							required={element && isRequired(element, "value")}
							fullWidth
            				multiline
							variant="outlined"
							placeholder="supported HTML"
							name="value"
							label="Value"
							value={value}
							onChange={handleValueChange}
							InputLabelProps={{
								shrink: true,
							}}
						/>
                    </div>
					<div>
						<FormControlLabel
							style={{paddingTop:"34px"}}
							control={<Checkbox checked={required} onChange={handleRequiredChange} name="required" />}
							label="Required"
						/>
					</div>
                    <div>
						<MUITextField
							style={{maxWidth:"60px"}}
							name="order"
							label="Order"
							value={order}
							onChange={handleOrderChange}
						/>
					</div>
					<div>
                        <Button
                            variant="outlined" color="primary"
                            style={{textTransform: 'none', maxWidth:"70px"}}
							disabled={disabled()}
                            onClick={()=>handleAddUpdateElement()}
                        >{mode === "ADD" ? "Add" : "Update"}
                        </Button>
                    </div>
                </div>
				<ListContextProvider value={{
					data: Object.assign({}, ...(data.map(item => ({ [item.id]: {...item} }) ))),
					ids: data.map(item => item.id),
					basePath: '/Field',
					resource: 'Field',
					setSort: (field, order) => {
						setSort({ field, order });
					},
					currentSort: sort,
					total: total.current,
					page,
					perPage,
					setPage,
					setPerPage,
				}}>
					<Datagrid
						style={{marginTop: "28px"}}
						checkboxSelection
						isRowSelectable={(record)=>{return true}}
					>
						<TextField source="display_order" label="Order"/>
						<TextFieldComponent label="Element"/>
						<TextFieldRequired label="Required"/>
						<TextField source="name" label="Name"/>
						<HTMLElement label="Rendered element"/>
						<EditElementButton />
						<DeleteElementButton />
					</Datagrid>
					<Pagination 
						page={page}
						perPage={perPage}
						setPage={setPage}
						total={total.current}
						rowsPerPageOptions={[10, 25, 50, 100]} 
					/>
				</ListContextProvider> 
            </CardContent>
        </Card>
    )
}