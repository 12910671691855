/* REFS
 * - https://codepulse.blog/how-to-store-draft-js-content/ 
 * - https://codepulse.blog/how-to-display-draft-js-content-as-html/
 * Styling
 * - https://blog.bitsrc.io/how-to-customize-material-ui-theme-v3-2-0-part-3-750db6981a33
 * - https://blog.bitsrc.io/a-better-way-to-style-material-ui-80c7707ad525
*/
import React, { useEffect } from 'react';
import FormControl from "@material-ui/core/FormControl";
import {
	/*EditorState,*/
	convertToRaw,
} from 'draft-js';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MUIRichTextEditor from 'mui-rte';


const RichTextEditor = props => {
	let	{ input, label, className } = props;

	/* const [editorState, setEditorState] = useState(EditorState.createEmpty()); */

	/*
	if (input.value)
		setEditorState(EditorState.createWithContent(convertFromHTML(input.value)));
   	*/

   	/* initilaize editor theme */
   	const defaultTheme = createTheme();
	Object.assign(defaultTheme, {
	    overrides: {
	        MUIRichTextEditor: {
	            editor: {
	                borderBottom: "1px solid gray",
	                resize: "vertical",
	                overflowY: "auto",
	                height:"64px"
	            },
				toolbar: {
					marginTop: label ? "46px" : "34px",
	                borderTop: "1px solid gray",
	                backgroundColor: "#ebebeb"
            	},
	        }
	    }
	});

	/* Post-render lifecycle
     * pass an empty array to useEffect, it’s only executed after the first render
	 * componentDidMount, componentWillUnmount: code in return part
    */
    useEffect(() => {
		return () => {
			/* put clean up code */
		}
	}, []);
			
	const handleChange = (editorState) => {

		const raw = convertToRaw(editorState.getCurrentContent());
        /* will crash if use `input.onChange(raw)` due to JSON.parse 
         * in https://github.com/niuware/mui-rte/blob/master/src/MUIRichTextEditor.tsx
         * at line 196
         *
         * NOTE: need to invoke onChange to update input or call setEditorState 
         * 	and use it to update input later.
        */
		input.onChange(JSON.stringify(raw));
		
		/* comment to supposely improve performace
		setEditorState({ editorState });
		*/

		/* save raw js object to local storage 
		 * JSON.stringify(raw));
		*/
	};

	return (
		<FormControl className={className}>
			{label && <InputLabel>{label}</InputLabel> }
			<MuiThemeProvider theme={defaultTheme}>
				<MUIRichTextEditor theme={defaultTheme}
					label="Type something here..."
					inlineToolbar={true}
	        		controls={["title", "bold", "italic", "underline", "numberList", "bulletList", "highlight", "quote", "code", "link", "clear"]}
	        		onChange={handleChange}
				/>
			</MuiThemeProvider>
		</FormControl>
	);
};

export default RichTextEditor;