import React from 'react';
import {
	FormControl,
	Input,
	InputLabel,
	InputAdornment,
} from "@material-ui/core";
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

import "./react-datepicker.css";
import DatePicker from "react-datepicker";

/** 
 * Hide virtual keyboard on mobile device with readOnly prop
 *  ref: https://github.com/Hacker0x01/react-datepicker/issues/221
 * And resolve warning
 *  Function components cannot be given refs.
 *  Attempts to access this ref will fail. Did you mean to use React.forwardRef()?
*/
const DatepickerInput = React.forwardRef((props, ref) => {
	const {value, disabled, inputStyle, error, ...rest} = props;
	return(
	<Input
		style={inputStyle}
		inputRef={ref}
		value={value}
		disabled={disabled}
		fullWidth
		error={error}
		type="text" 
		inputProps={{...rest}} 
		readOnly
		startAdornment={
			<InputAdornment>
				<ScheduleOutlinedIcon />
			</InputAdornment>
		}
	/>);
})

const DateTimePicker = props => {
	/* input and meta props are only available if the component is rendered with Field of react-final-from for instance */
	const { name, label, classes, selected, required, disabled, inputStyle, onChange, includeTime = false, ...rest } = props;
	
	/* include the current time in date picker */
	const handleOnChange = (dates) => {
		if(onChange && includeTime){
			const isArray =  Array.isArray(dates);
			/* clone the prop to void unexpected results */
			let dateArray = !isArray 
				? (dates ? [new Date(dates.getTime())] : [null]) 
				: dates.map(item => item ? new Date(item.getTime()) : item);

			const currentDate = new Date();
			dateArray = dateArray.map(item => {
				if(!item) return item;
				item.setHours(currentDate.getHours());
				item.setMinutes(currentDate.getMinutes());
				item.setSeconds(currentDate.getSeconds());
				return item;
			});

			onChange(isArray ? dateArray : dateArray[0]);
		}
		else if(onChange) onChange(dates);
	}

	return (
		<FormControl style={{paddingTop: '16px'}}>
		<InputLabel 
			style={{marginLeft: "28px"}}
			required={required} 
			disabled={disabled}
			shrink={!!selected}
		>{label}
		</InputLabel>
		<DatePicker
			customInput={
				<DatepickerInput id={name} inputStyle={inputStyle}/>
			}
			selected={selected}
			name={name}
			disabled={disabled}
			className={classes}
			onChange = {handleOnChange}
			{...rest}
		/>
		</FormControl>
	);
};

export default DateTimePicker;