import React, { cloneElement } from 'react';
import { Link } from 'react-router-dom';
import { List, Edit, Pagination, Datagrid, SimpleForm, downloadCSV, 
         TextField, DateField, BooleanField, 
         TextInput, ReferenceInput, SelectInput, DateInput,  
         Button, CreateButton, SaveButton, ExportButton,
         Filter} from 'react-admin';

import jsonExport from 'jsonexport/dist';

import Toolbar from '@material-ui/core/Toolbar';
import EditIcon from '@material-ui/icons/Edit';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

/* old button
import FollowUpButton from './components/Booking/FollowUpButton';
*/
import config from 'config';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    headerCell:{
        '&:last-child': {
            maxWidth: 16,
        }
    },
    rowCell:{
        '&:last-child': {
            maxWidth: 16,
        }
    },
    /*
    rowEven: {
        backgroundColor: '#ffffff',
    },
    rowOdd: {
        backgroundColor: '#d9e1f2',
    },
    */
});

/* set row background color based on deal_status */
const BookingRowStyle = (record, index) => {
    let backgroundColor = 'white';
    if(record.deal_status === 1) backgroundColor = "#f44a37"
    else if(record.deal_status === 2) backgroundColor = "#5bd952";
    else if(index%2) backgroundColor = '#d9e1f2';
    return { backgroundColor: backgroundColor};
}

const FILTER_SALUTATION = config.booking.FILTER_SALUTATION;
const FILTER_PROGRAM_INTERESTED = config.booking.FILTER_PROGRAM_INTERESTED;
const FILTER_WHO_FOLLOW_UP = config.booking.FILTER_WHO_FOLLOW_UP;
const FILTER_STATUS = config.booking.FILTER_STATUS;
const FILTER_DEAL_STATUS = config.booking.FILTER_DEAL_STATUS;

/* Customize exporter */
const exporter = bookings => {
    const bookingsForExport = bookings.map(booking => {
        const { id, centre_email, pipeline_id, stage_id, deal_id, deal_status, person_id, salutation, who_follow_up, ...bookingForExport } = booking; // omit somw fields
        /* can add a field
        bookingForExport.field = booking.field;
        */
        return bookingForExport;
    });
    jsonExport(bookingsForExport, {
        headers: ["name", "code", "time_slot", "parent_name", "child_name", "date_of_birth", "mobile_no", "contact_email", "program_interested", "walk_in", "status"/*, "who_follow_up"*/], // order fields in the export
        rename:["Centre", "Booking Code", "Time Slot", "Parent name", "Child Name", "DOB", "Mobile", "Email", "Program", "Walk-in", "Status", /*"Follow up"*/]
    }, (err, csv) => {
        downloadCSV(csv, 'bookings'); // download as 'bookings.csv` file
    });
};

const BookingPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

/* Remove the export action in an list view */
const BookingActions = ({
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    hasCreate,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    /* NOTE: If we use react-admin Toolbar will cause resource is null when pass to provider.
     * Then export function will be crashed
    */
    <Toolbar>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={{ ...filterValues, ...permanentFilter }}
                exporter={exporter}
            />
        )}
        {/* Add your custom actions
        <UIButton color="primary" onClick={null}>Custom</UIButton>
        */}
    </Toolbar>
);

/* Remove the <DeleteButton> from the toolbar in an edit view */
const BookingEditToolbar = props => {
    return (
        <Toolbar {...props} >
            <SaveButton {...props} />
        </Toolbar>
    )
};

const BookingFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Mobile" source="mobile_no" alwaysOn />
        <TextInput label="Email" source="contact_email" alwaysOn />
        <SelectInput 
            label="Follow-up"
            source="who_follow_up"
            choices={FILTER_WHO_FOLLOW_UP}
            alwaysOn
        />
        <SelectInput 
            label="Status"
            source="status"
            choices={FILTER_STATUS}
            /*allowEmpty emptyValue={"None"}*/
            alwaysOn
        />
        <DateInput 
            label="From"
            source="time_slot_from" 
            alwaysOn
        />
        <DateInput
            label="To"
            source="time_slot_to"
            alwaysOn
        />

        <TextInput label="Code" source="code" />
        <TextInput label="Parent" source="parent_name" />
        <TextInput label="Child" source="child_name" /> 
        {/* get all centres to populate in select component */}
        <ReferenceInput perPage={999} sort={{field: 'name', order: 'ASC'}} label="Centre" source="centre_email" reference="Centre" allowEmpty>
            <SelectInput optionText="name" optionValue="email" />            
        </ReferenceInput>
        <SelectInput label="Program" source="program_interested" choices={FILTER_PROGRAM_INTERESTED}/>
        <SelectInput label="Deal" source="deal_status" choices={FILTER_DEAL_STATUS}/>
    </Filter>
);

/* Add new activity button to follow up deals */
const AddActivityButton = ({ record }) => {
    return(
        <Button
            component={Link}
            to={{
                pathname: `/VMSActivity`,
                search: `?deal_id=${record.deal_id}&centre_email=${record.centre_email}`,
                obj_data: record,
            }}
            
            /*onClick={(e)=>{window.location.href = activityURL}}*/
            title="Follow-up"
        >
            <ChatBubbleIcon />
        </Button>
    );
}

/* Replace default EditButton component to add more params */
const EditAppointmentButton = ({ record }) => {
    return(
        <Button
            component={Link}
            to={{
                pathname: `/Appointment/${record.id}`,
                /* pass centre_email to BookingEdit (location.centre_email)
                 * then save to local storage and use in httpClient later
                */
                centre_email: record.centre_email,
            }} 
            /*onClick={(e)=>{window.location.href = activityURL}}*/
            title="Edit"
        >
            <EditIcon />
        </Button>
    );
}

export const BookingList = props => {
    /*
    let objActivity = localStorage.getItem('activity');
    console.log("TRY:", objActivity.appointment);
    */
    const classes = useStyles();
    return (
        <List {...props} 
            exporter={exporter}
            pagination={<BookingPagination />}
            perPage={25}
            actions={<BookingActions />} 
            filters={<BookingFilter />} 
            sort={{ field: 'status', order: 'DESC' }}
            /*
            filterDefaultValues={{ 
                status: "none", 
                who_follow_up: "none" 
            }}
            */
            title="Bookings" 
            bulkActionButtons={false} 
        >
            <Datagrid 
                optimized 
                rowStyle={BookingRowStyle}
                classes={{
                    headerCell: classes.headerCell,
                    rowCell: classes.rowCell
                    /*
                    rowEven: classes.rowEven,
                    rowOdd: classes.rowOdd
                    */
                }}
            >
                 { /* Original solution is to use ReferenceInput to call Centre API to fetch centre name.
                    * However, this one might cause a lot of API calls, so Appointment is now support name
                <ReferenceField 
                    label="Centre"
                    source="centre_email"
                    reference="Centre" 
                    linkType={true}
                >
                    <TextField source="name" />
                </ReferenceField>
                */}
                <TextField source="name" />
                <TextField source="code" />
                <DateField 
                    source="time_slot"
                    label="Slot"
                    locales="sg-SG"
                    options={{ day: '2-digit', month: '2-digit', year: 'numeric', hour: "numeric", minute:"numeric" }} 
                    showTime={true} />
                <TextField source="parent_name" />
                <TextField source="child_name" />
                <DateField 
                    source="date_of_birth"
                    label="DOB"
                    locales="sg-SG"
                    options={{ day: '2-digit', month: '2-digit', year: 'numeric' }} 
                />
                <TextField source="mobile_no" />
                {/*<TextField source="contact_email" />*/}
                <TextField source="program_interested" label="Program" />
                <BooleanField source="walk_in" label="Walkin"/>
                <TextField source="status" />
                <TextField source="who_follow_up" label="F/U" />
                {/*
                <EditButton />
                */}
                <EditAppointmentButton />
                {/*
                <FollowUpButton/>
                */
                }
                <AddActivityButton/>
            </Datagrid>
        </List>
    )
};

export const BookingEdit = props => {
    /* need to add centre_email to localStorgage then httpClient put in header to call API */
    localStorage.setItem('centre_email', props.location.centre_email);
    let group = localStorage.getItem("group");
    let disable_info = ["Reader", "Writer"].includes(group) ? true : false,
        disable_fu = group === "Reader" ? true : false;
    return (
        <Edit {...props} 
            title="Edit booking">
            <SimpleForm toolbar={<BookingEditToolbar />}>
                <SelectInput disabled={disable_info} label="Salutation" source="salutation" choices={FILTER_SALUTATION}/>
                <TextInput disabled={disable_info} source="parent_name" />
                <TextInput disabled={disable_info} source="child_name" />
                <DateInput disabled={disable_info} source="date_of_birth" />
                <TextInput disabled={disable_info} source="mobile_no" />
                <TextInput disabled={disable_info} source="contact_email" />
                <SelectInput disabled={disable_info} label="Program" source="program_interested" choices={FILTER_PROGRAM_INTERESTED}/>
                <SelectInput disabled={disable_fu} label="Next Follow Up By" source="who_follow_up" choices={FILTER_WHO_FOLLOW_UP}/>
            </SimpleForm>
        </Edit>
    );
}