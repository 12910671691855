import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, FormControl, FormControlLabel, FormLabel, TextField, Checkbox, RadioGroup, Radio } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    label: { /* simulate FormLabel */
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "1rem",
        padding: "6px 0 7px",
        /*
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: "0.00938em",
        */
    }
}));

const HTMLElement = (props) => {
    const {name, component, type, caption, value, required, classes, inline = true} =  props.record;
    const _classes = useStyles();
    /* refs
     * - https://dirask.com/posts/JavaScript-split-string-by-new-line-character-k1wEQp
    */
    const _split = (text) => (text.split(/\r\n|\n\r|\n|\r/).map(line => line.trim()).filter(item=>item!==""));

    if(component === "input"){
        return (
            <TextField
                name={name}
                fullWidth={true}
                className={classes}
                label={caption}
                value={value || ""}
                type={type}
                required={required == 1}
            />
        );
    }
    else if(component === "label"){
        return (<>
            {caption && <div className={_classes.label} /*style={{whiteSpace: "pre-wrap"}}*/ dangerouslySetInnerHTML={{__html: caption}} />}
            {value && (<div className={classes} /*style={{whiteSpace: "pre-wrap"}}*/ dangerouslySetInnerHTML={{__html: value}} />)}
        </>
        );
    }
    else if(component === "checkbox"){
        const options = _split(value);
        return (
            <FormControl component="fieldset">
                <FormLabel component="legend">{caption}</FormLabel>
                <FormGroup>
                    {options.map((val, idx) => (
                        <FormControlLabel
                            key={idx}
                            control={<Checkbox  name={val} />}
                            label={val}
                        />
                    ))}
                </FormGroup>
            </FormControl>
        );
    }
    else if(component === "radio"){
        const options = _split(value);
        return (
        <FormControl required={required == 1}>
            <FormLabel>{caption}</FormLabel>
            <RadioGroup row={true}>
                {options.map((val, idx) => (
                    <FormControlLabel
                        key={idx}
                        name={name}
                        label={val}
                        value={val}
                        control={
                            <Radio
                                name={name}
                                required={required}
                            />
                        }
                    />
                ))}
            </RadioGroup>
        </FormControl>
        )
    }
    else{
        return (
            <div/>
        )
    }
}

export default HTMLElement;