import React, { Component } from "react";

import config from 'config';
import { Admin, Resource } from 'react-admin';
import AppLayout from './app/layouts/AppLayout';
import Theme from './Theme';

import closeSidebarSaga from './sagas/closeSidebarSaga';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

/* import activityReducer from './reducers/activityReducer';*/

import { UserList, UserEdit } from './ui/User';
import { BookingList, BookingEdit } from './ui/Booking';
import { CentreList } from './ui/Centre';
import { GroupList } from './ui/Group';
import { Blocking } from "./ui/Blocking";
import FieldPanel from "./ui/FieldPanel";
import EmailPanel from "./ui/EmailPanel";
import Timeline from './components/Timeline/Timeline';

const apiURL = config.app.back_end.url;
 
class App extends Component {
  render() {
    return (
      <Admin 
        /*customReducers={{ activity: activityReducer }}*/
        customSagas={[ closeSidebarSaga ]}
        theme={Theme}
        layout={AppLayout}
        authProvider={authProvider(apiURL)}
        dataProvider={dataProvider(apiURL)}>
        <Resource 
          label="Appointment" 
          name="Appointment"  
          list={BookingList}
          edit={BookingEdit} 
          options={{ label: 'Appointment' }} 
        />
        {/* commment `list={CentreList}` to hide Centre */}
        <Resource name="Centre" list={CentreList} />
        <Resource name="Group" list={GroupList} />
        <Resource 
          name="VMSActivity"
          list={Timeline}
          options={{ label: 'Activities' }} 
        />
        <Resource 
          name="User"
          list={UserList} 
          edit={UserEdit} 
        />
      <Resource
        name="Blocking"
        list={Blocking}
      />
      <Resource
        name="Field"
        list={FieldPanel}
      />
      <Resource
        name="Template"
        list={EmailPanel}
      />
    </Admin>
    );
  }
}
 
export default App;
