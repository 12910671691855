import React from 'react';
import { Sidebar as RaSidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useSidebarStyles = makeStyles({
	/*
    drawerPaper: {
        width:127,
        closedWidth: 55,
    },
 	*/
});

const Sidebar = props => {
    const classes = useSidebarStyles();
    return (
        <RaSidebar classes={classes} {...props} />
    );
};

export default Sidebar;