import React, { Component, Fragment } from "react";
import { Toolbar, fetchStart, fetchEnd, CREATE } from 'react-admin';

import { Form, Field } from 'react-final-form';

import { Box } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import SelectField from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';

import config from 'config';

/* custom components */
import  RichTextEditor from '../FormElement/RichTextEditor';
import MessageBar from '../Message/MessageBar';
import draftToHtml from 'draftjs-to-html';

import dataProvider from '../../dataProvider';

const FormRequired = value => {
	return (value ? undefined : "Required");
}

class EventCreateButton extends Component{

	constructor(props) {
        super(props);

        this.state = {
        	showDialog: false,
			error: false,
			error_message: null,
			isSubmitting: false,
			/* addtional keys */
			type: null,
			status: null,
			stage:null,
		}
    }

    resetState = () =>{
    	this.setState({ 
    		showDialog: false,
    		error: false,
			error_message: null,
			isSubmitting: false,
			/* addtional keys */
			type: null,
			status: null,
			stage:null,
    	});
    }

	renderTextField = ({
		input,
		label,
		rows,
		style,
		className,
		meta: { touched, error },
		children,
		...custom
		}) => {
		return (
			<FormControl className={className}>
				<TextField
					label={label}
					multiline
					rows={rows ? rows : 2}
					errorText={touched && error}
					{...input}
					children={children}
					{...custom}
				/>
			</FormControl>
		);
	}

	renderSelectField = ({
		input,
		label,
		style,
		className,
		meta,
		children,
		...custom
		}) => {
		return(
			/*<Fragment>*/
			<FormControl className={className} {...input} >
				<InputLabel>{label}</InputLabel>
				<SelectField
					floatingLabelText={label}
					/* errorText not working, so self implement below
					errorText={meta.touched && meta.error}
					*/
					error={meta.touched && meta.error}
					{...input}
					children={children}
					{...custom}
					onChange={(event, idx) => this.handleSelectChange(input, event)}
				/>
			</FormControl>
			/*
			<div>
			{meta.error && meta.touched && <span style={{color:"#f44336", lineHeight: "60px", fontSize: "12px"}}>{meta.error}</span>}
			</div>
			</Fragment>
			*/
		);
	}

	handleSelectChange = (input, event) => {
		const target = event.target;
	    const name = target.name,
	    	  value = target.type === 'checkbox' ? target.checked : target.value;

	    input.onChange(value);

	    this.setState({ [name]:value });
	}

	renderDateField = ({
		input,
		label,
		rows,
		style,
		className,
		meta,
		children,
		...custom
		}) => {
		return (
			<FormControl className={className}>
				<TextField
					type="date"
					label={label}
					/* errorText not working, so self implement below */
					error={meta.touched && meta.error}
					{...input}
					children={children}
					{...custom}
					className="textField"
				    InputLabelProps={{
				      shrink: true,
				    }}
				/>
			</FormControl>
		);
	}

	handleClick = () => {
		this.setState({ 
    		showDialog: true,
    		error: false,
			error_message: null,
			isSubmitting: false,
			/* addtional keys */
			type: null,
			status: null,
			stage:null,
    	});
	}

	onClose = () => {
		this.resetState();
	}

	handleSubmit = async json => {
		/* very important to make MessageBar works correctly*/
		this.setState({ error: false, isSubmitting: true });
		/* check submitted json: console.log(JSON.stringify(json, 0, 2)); */
		let key_value = {
			centre_email: this.props.obj_data.centre_email,
			contact_name: this.props.obj_data.parent_name,
			contact_email: this.props.obj_data.contact_email,
			pipeline_id: this.props.obj_data.pipeline_id,
			stage_id: this.props.obj_data.stage_id,
			deal_id: this.props.obj_data.deal_id,
			person_id: this.props.obj_data.person_id,

		};
		/* add addtional fields from UI: type, content, note, status, stage */
		for(let key in json)
			key_value[key] = json[key];
		key_value.code = this.props.obj_data.code;

		if(key_value.content)
			key_value.note = draftToHtml(JSON.parse(key_value.content));

		/* add deal customized_field if available 
		 * Object { status: "won", status_won_date: "2020-03-04", status_won_commencement_date: "2020-03-20" }
		 * Object { status: "lost", status_lost_reason: "Other....", status_lost_reason_other: "Other reason" }
		*/
		let deal_customized_field = {};
		if(key_value.status === "won"){
			key_value.deal_status = 2;
			/* clear all status lost if user selected earlier */
			if(key_value.status_lost_reason) delete key_value.status_lost_reason;
			if(key_value.status_lost_reason_other) delete key_value.status_lost_reason_other;

			if(key_value.status_won_date) deal_customized_field["Won time"] = key_value.status_won_date;
			if(key_value.status_won_commencement_date) deal_customized_field["Confirmed Start Date"] = key_value.status_won_commencement_date;
		}
		else if(key_value.status === "lost"){
			key_value.deal_status = 1;
			/* clear all status won if user selected earlier */
			if(key_value.status_won_date) delete key_value.status_won_date;
			if(key_value.status_won_commencement_date) delete key_value.status_won_commencement_date;
				
			if(this.state.status_lost_reason === "Other....") deal_customized_field["Lost reason"] = key_value.status_lost_reason_other;
			else if(key_value.status_lost_reason) deal_customized_field["Lost reason"] = key_value.status_lost_reason;
		}
		if(Object.keys(deal_customized_field).length){
			deal_customized_field.centre_email = this.props.obj_data.centre_email;
			key_value.deal_customized_field = deal_customized_field;
		}

		/* dispatch an action letting react-admin know a API call is ongoing
		 * 
		 * NOTE:
		 *	As we want to know when the new activity has been created in order to close the modal,
		 *	we use the dataProvider directly.
		*/
    	fetchStart();

		dataProvider()(CREATE, "VMSActivity", { data: key_value })
		.then(({ data }) => {
			this.setState({ error: false, showDialog: false, isSubmitting: false });
			this.props.handleLoadAll();
		})
		.catch(error => {
			this.setState({ error: true, error_message: error.message, isSubmitting: false});
		})
		.finally(() => {
			// letting react-admin know a API call has ended
			fetchEnd();
		});
	}

	renderSelectType() {
		return config.timeline.ACTIVITY_TYPE.filter(data => data.enable).map((data, idx) => {
			return (
				<MenuItem
					key={data.key_string}
					value={data.key_string}>
					{data.lable}
				</MenuItem>
			);
		});
	}

	renderSelectStatus() {
		let objStatus = config.timeline.ACTIVITY_STATUS;
		return Object.keys(objStatus).map((label, index) => {
			return (
				<MenuItem
					key={objStatus[label]}
					value={objStatus[label]}>
					{label}
				</MenuItem>
			);
		});
	}

	renderSelectStatusLostReason() {
		let objStatus = config.timeline.ACTIVITY_STATUS_LOST_REASON;
		return Object.keys(objStatus).map((label, index) => {
			return (
				<MenuItem
					key={objStatus[label]}
					value={objStatus[label]}>
					{label}
				</MenuItem>
			);
		});
	}

	renderSelectStage() {
		let objStage = config.timeline.ACTIVITY_STAGE;
		return Object.keys(objStage).map((label, index) => {
			return (
				<MenuItem
					key={objStage[label]}
					value={objStage[label]}>
					{label}
				</MenuItem>
			);
		});
	}

	render() {
		const { error, error_message, showDialog, isSubmitting } = this.state;
		
		return(
			<Fragment>
				<Button 
					color="primary"
					startIcon={<AddIcon />}
					onClick={this.handleClick}
				>Add activity
				</Button>
				<Dialog
					fullWidth
					open={showDialog}
					onClose={this.onClose}
					aria-label="Create activity"
				>
					<DialogTitle>Create activity</DialogTitle>
					<DialogContent>
						<Form
						    onSubmit={this.handleSubmit}
						    render={({ handleSubmit, form, submitting, pristine, values }) => (
					            // here starts the custom form layout
				            <form onSubmit={handleSubmit}>
				                <Box>
				                    <Box display="flex">
										<Field
											/*
											validate={FormRequired}
											label="Type *"
											*/
											label="Select Mode of Communication with Parent"
											name="type"
											className="box-fullwith"
											component={this.renderSelectField}
										>
											{this.renderSelectType()}
										</Field>
				                    </Box>

				                    <Box mt="0.2em" />

				                    <Box display="flex">
				                    	{/*
				                    	<Field
				                    		label="Comment"
				                    		name="note"
				                    		className="box-fullwith"
				                    		component={this.renderTextField}
				                    	>
				                    	</Field>
				                    	*/}

										<Field
											label="Details of Communication with Parent"
											name="content"
											className="box-fullwith"
											component={RichTextEditor}
										>
										</Field>
				                    </Box>

				                    <Box mt="0.4em" />

				                    <Box display="flex">
										<Field
											label="Move Deal To:"
											name="stage"
											className="box-fullwith"
											component={this.renderSelectField}
										>
											{this.renderSelectStage()}
										</Field>
				                    </Box>

				                    <Box mt="0.2em" />

				                    <Box display="flex">
										<Field
											label="Select if Deal is WON or LOST"
											name="status"
											className="box-fullwith"
											component={this.renderSelectField}
										>
											{this.renderSelectStatus()}
										</Field>
				                    </Box>

				                	{/* dynamically show won & commencement dates*/}
				                    {this.state.status === "won" && 
				                    <Box mt="0.4em" display="flex">
				                    	<Box flex={1} mr="0.5em">
				                    		<Field
												validate={FormRequired}
											    name="status_won_date"
											    label="Date Payment Received (*)"
											    className="box-fullwith"
											   	component={this.renderDateField}
											/>
										</Box>
				                    	<Box flex={1} mr="0.5em">
							                <Field
							                	validate={FormRequired}
											    name="status_won_commencement_date"
											    label="Commencement Date (*)"
											    className="box-fullwith"
											   	component={this.renderDateField}
											/>
										</Box>
					                </Box>}

					            {/* dynamically show reasons */}
				                    {this.state.status === "lost" && 
				                    <Box display="flex">
										<Field
											validate={FormRequired}
											label="Lost reason (*)"
											name="status_lost_reason"
											className="box-fullwith"
											component={this.renderSelectField}
										>
											{this.renderSelectStatusLostReason()}
										</Field>
				                    </Box>}

				                    {this.state.status === "lost" && 
				                     this.state.status_lost_reason === "Other...." && 
				                    <Box mt="0.4em" display="flex">
										<Field
											label="Other lost reason"
											name="status_lost_reason_other"
											className="box-fullwith"
											component="textarea"
										>
										</Field>
				                    </Box>}
				                </Box>
				                {error && <Box mt="0.2em" >
					                <MessageBar
					                	show={error}
								        variant="error"
								        message={error_message}
								    />
				                </Box>}
				                <Toolbar>
				                    <Box display="flex" justifyContent="space-between" width="100%">
				                    	{/* SaveButton not submitting form, that why use materia;-ui button */}
							            <Button 
							            	disabled={isSubmitting || submitting || pristine} 
							            	type="submit"
							            	variant="contained" color="primary"
							            	startIcon={<SaveIcon />}
							            >Submit
							            </Button>
				                		<Button onClick={this.onClose}
				                			variant="outlined"
											startIcon={<CancelIcon />}
										>Cancel
										</Button>
				                    </Box>
				                </Toolbar>
				            </form>
					        )}
					    />
					</DialogContent>
				</Dialog>
			</Fragment>
		)
	}
}

export default EventCreateButton;