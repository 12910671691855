import React from "react";
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Sidebar from './Sidebar';
import AppMenu from './AppMenu';
import AppNotification from './AppNotification';

const AppLayout = (props) => {
	return (
		<Layout 
			{...props}
			appBar={AppBar}
			sidebar={Sidebar}
			menu={AppMenu} 
			notification={AppNotification} 
		/>
	);
}

export default AppLayout;