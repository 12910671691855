import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNotify,  ListContextProvider, 
		 Datagrid, Pagination, TextField, ReferenceField, ChipField  } from 'react-admin';
import { Loading, Error } from 'react-admin';
import { stringify } from 'query-string';

import {
	FormControl,
	Button,
	Card,
	CardContent,
	CardHeader,
	InputLabel,
	Select, MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import DeleteIcon from '@material-ui/icons/Delete';

import api from '../../utils/api';
import config from 'config';

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		/* the parent card set overflow causes datepicker is hiddern behind */
		overflow: "unset",
	},
	widgetHeader: {
		backgroundColor: theme.widget.headerBackgroundColor,
		padding: 7,
		color: theme.widget.headerColor,
        fontWeight: 600,
	},
}));

export const Range = () => { 
    const classes = useStyles();
	const notify = useNotify();

	const [data, setData] = useState([]);
	const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);

	const [excludeDates, setExcludeDate] = useState([])
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
	const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
	const [minTime, setMinTime] = useState();
	const [maxTime, setMaxTime] = useState();
	const [centre, setCentre] = useState('all');

	const centres = useRef([]);
	const total = useRef(0);

	const [sort, setSort] = useState({ field: 'id', order: 'ASC' });
	const [page, setPage] = useState(1);
  	const [perPage, setPerPage] = useState(10);

	const fetchData = useCallback(async () => {
		try{
			/* build sort and paging */
			const queries = {}
			queries.offset = (page - 1) * perPage;
			queries.count = perPage;
			queries.order_by = `${sort.field}  ${sort.order}`
			if(centre !== "all") queries.query = `centre=${centre}`;
	
			const _centres = await api('get', `${config.app.back_end.url}/Centre?field=id,email,name,closing_date,opening_time,closing_time&order_by=name ASC`);
			centres.current = _centres.data;

			const blockings = await api('get', `${config.app.back_end.url}/Blocking?${stringify(queries)}`);
			total.current = blockings.range.total;
			setData(blockings.data);
			setLoaded(true);
		}
		catch(e){
			setError(true);
		}
		
	}, [centre, sort, page, perPage])

	useEffect(() => {
		fetchData();
	}, [fetchData])

	const handleCentreChange = (e) => {
		setCentre(e.target.value);
		const selectedCentre = centres.current.find(_centre => _centre.email === e.target.value);

		/* calculate exclude dates */
		const arrayClosingDate = selectedCentre.closing_date ? JSON.parse(selectedCentre.closing_date).map(closing_date => new Date(closing_date)) : [];
		setExcludeDate(arrayClosingDate);

		/* calculate min and max times */
		const currentMinDateTime = new Date(), currentMaxDateTime = new Date();
		const startHours = selectedCentre.opening_time ? Math.floor(selectedCentre.opening_time/60) : 7;
		const startMinutes = selectedCentre.opening_time ? selectedCentre.opening_time%60 : 30;
		currentMinDateTime.setHours(startHours);
		currentMinDateTime.setMinutes(startMinutes);
		const endHours = /*selectedCentre.closing_time ? Math.floor(selectedCentre.closing_time/60) : 17;*/ 19;
		const endMinutes = /*selectedCentre.closing_time ? selectedCentre.closing_time%60 : 30;*/ 30;
		currentMaxDateTime.setHours(endHours);
		currentMaxDateTime.setMinutes(endMinutes);
		setMinTime(currentMinDateTime);
		setMaxTime(currentMaxDateTime);

	}

    const handleAddDateRange = async () => {
		try{
			/*
			const ISODateFormat = '2021-07-19T13:04:20.602Z';
			const regex = ISODateFormat.match(/([0-9]{4}-[0-9]{2}-[0-9]{2})?.([:0-9]+)/);
			expected output:  Array ["2021-07-19T13:04:20", "2021-07-19","13:04:20"]
			const regex = ISODateFormat.match(/\d\d:\d\d/);
			expected output:  Array ["13:04"]
			*/
			if(!centre) throw {message: "missing centre"};

			else if(!startDate && !endDate && !startTime && !endTime)  throw "select date range and/or recurring start/end time";
			else if((startTime && !endTime))  throw "missing recurring end time";
			else if((!startTime && endTime))  throw "missing recurring start time";
			else if(startTime > endTime) throw "end time must be after start time";
			const req = {
				centre: centre, 
				date_start: startDate ? startDate.toISOString().split('T')[0] : null, 
				date_end: endDate ? endDate.toISOString().split('T')[0] : null,
				time_start: startTime ? ("0" + startTime.getHours()).substr(-2) + ":" + ("0" + startTime.getMinutes()).substr(-2) : null,
				time_end: endTime ? ("0" + endTime.getHours()).substr(-2) + ":" + ("0" + endTime.getMinutes()).substr(-2) : null,
			};
			const response = await api('post', `${config.app.back_end.url}/Blocking`, req);
			let _data = data.map(item => ({...item}));
			_data.push(response.data);
			total.current += 1;
			setData(_data);
		}
		catch(err){
			notify(`Could not add new blocking range: ${err}`, 'error');
		}
    }

	const handleDeleteDateRange = async (record) => {
		try{
			await api('delete', `${config.app.back_end.url}/Blocking/${record.id}`);
			let _data = data.filter(item => (item.id !== record.id));
			setData(_data);
		}
		catch(err){
			notify(`Could not delete the blocking range: ${err}`, 'error');
		}
	}

	const DeleteRangeButton = ({ record }) => {
		return(
			<Button
				onClick={()=>{handleDeleteDateRange(record)}}
			><DeleteIcon />
			</Button>
		);
	}

	const handleImport = async () => {
        try{
            await api('post', `${config.app.back_end.url}/Blocking/import`);
        }
        catch(err){
            notify(`Failed to import: ${err}`, 'error');
        }
    }

	if (!loaded) { return <Loading />; }
    if (error) { return <Error />; }
    if(!data) return null;

    return (
        <Card className={classes.root}>
            <CardHeader
				title={`Blocking date/time ranges`}
				className={classes.widgetHeader}
				titleTypographyProps={{ variant: "subtitle1", fontWeight:'bold' }}
			></CardHeader>
            <CardContent>
                <div
                    style={{ 
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                        columnGap: "16px",
                    }}
                >
					<div>
						<FormControl required>
							<InputLabel shrink={!!centre}>Select centre</InputLabel>
							<Select
								style={{minWidth: 127}}
								name="centre-label"
								id="centre"
								displayEmpty
								value={centre}
								onChange={handleCentreChange}
								>
								<MenuItem key={"all"} value={"all"}>{"All"}</MenuItem>
								{centres.current.map(item => (
									<MenuItem key={item.email} value={item.email}>{item.name}</MenuItem>
								))}
							</Select>
						</FormControl>
                    </div>
                    <div>
                        <DateTimePicker
							inputStyle = {{minWidth: "250px"}}
							name="date-range"
							label="Select date range"
							includeTime={true}
                            selected={startDate}
                            onChange={(dates) => {
								if(dates){
									const [start, end] = dates;
									setStartDate(start);
									setEndDate(end);
								}
								else{
									setStartDate("");
									setEndDate("");
								}
							}}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
							minDate={new Date()}
							excludeDates={excludeDates}
							isClearable
							/*monthsShown={2}*/
                        />
                    </div>
                    <div>
						<DateTimePicker
							name="start-time"
							label="Select recurring start time"
							selected={startTime}
							onChange={startTime => setStartTime(startTime)}
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={30}
							timeCaption="Time"
							dateFormat="HH:mm"
							timeFormat="HH:mm"
							minTime={minTime}
							maxTime={maxTime}
							isClearable
						/>
					</div>
					<div>
						<DateTimePicker
							name="end-time"
							label="Select recurring end time"
							selected={endTime}
							onChange={endTime => setEndTime(endTime)}
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={30}
							timeCaption="Time"
							dateFormat="HH:mm"
							timeFormat="HH:mm"
							minTime={minTime}
							maxTime={maxTime}
							isClearable
						/>
					</div>
					<div>
                        <Button
                            variant="outlined" color="primary"
                            style={{textTransform: 'none', maxWidth:"70px"}}
                            onClick={()=>handleAddDateRange()}
                        >Add
                        </Button>
                    </div>
					<div style={{display: "none"}}>
                        <Button
                            variant="outlined" color="primary"
                            style={{textTransform: 'none'}}
                            onClick={()=>handleImport()}
                        >Import
                        </Button>
                    </div>
                </div>
				<ListContextProvider value={{
					data: Object.assign({}, ...(data.map(item => ({ [item.id]: {...item} }) ))),
					ids: data.map(item => item.id),
					basePath: '/Blocking',
					resource: 'Blocking',
					setSort: (field, order) => {
						setSort({ field, order });
					},
					currentSort: sort,
					total: total.current,
					page,
					perPage,
					setPage,
					setPerPage,
				}}>
					<Datagrid
						style={{marginTop: "28px"}}
					>
						<TextField source="id" label="ID"/>
						<ReferenceField label="Centre" source="centre" reference="Centre" link={false} >
							<ChipField source="name" />
						</ReferenceField>
						<TextField source="date_start" label="Start date"/>
						<TextField source="date_end" label="End date"/>
						<TextField source="time_start" label="Start time"/>
						<TextField source="time_end" label="End Time"/>
						<DeleteRangeButton/>
					</Datagrid>
					<Pagination 
						page={page}
						perPage={perPage}
						setPage={setPage}
						total={total.current}
						rowsPerPageOptions={[10, 25, 50, 100]} 
					/>
				</ListContextProvider> 
            </CardContent>
        </Card>
    )
}