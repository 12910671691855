import React, { Component, Fragment } from "react";
import { fetchStart, fetchEnd, GET_LIST, CREATE, 
		 Toolbar, required, minLength,
		 TextInput, SelectArrayInput, PasswordInput,
		 regex
		} from 'react-admin';

import { Form, Field } from 'react-final-form';

import { Box } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import SelectField from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from '@material-ui/core/Button';

import config from 'config';

/* custom components */
import MessageBar from '../Message/MessageBar';
import dataProvider from '../../dataProvider';

import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha512';

const FormRequired = value => {
	return (value ? undefined : "Required");
}

const validatePassword = regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/, 'The password must be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character');

class UserCreateButton extends Component{
	constructor(props) {
        super(props);

        this.state = {
			error: false,
			error_message: null,
			showDialog: false,
			isSubmitting: false,
		};
       
        this.arrCentre = null;
    }

	async componentDidMount() {
        fetchStart();
        dataProvider()(GET_LIST, "Centre", {})
        .then((res) => {
        	this.setState({ error: false, showDialog: false, isSubmitting: false });
        	this.arrCentre = res.data;
        })
        .catch(/*error*/() => {

        })
        .finally(() => {
        	fetchEnd();
        })
    }

    renderSelectField = ({
		input,
		label,
		style,
		className,
		meta,
		children,
		...custom
		}) => {
		return(
			<Fragment>
			<FormControl className={className} {...input} style={style}>
				<InputLabel>{label}</InputLabel>
				<SelectField
					floatingLabelText={label}
					/* errorText not working, so self implemnt below */
					errorText={meta.touched && meta.error}
					{...input}
					children={children}
					{...custom}
				/>
			</FormControl>
			<div>
			{meta.error && meta.touched && <span style={{color:"#f44336", lineHeight: "60px", fontSize: "12px"}}>{meta.error}</span>}
			</div>
			</Fragment>
		);
	}

    renderSelectRole() {
		return config.user.ROLE.filter(data => data.enable).map((data) => {
			return (
				<MenuItem
					key={data.key_string}
					value={data.key_string}>
					{data.lable}
				</MenuItem>
			);
		});
	}

	handleClick = () => {
		this.setState({ error:false, showDialog: true });
	};

	handleCloseClick = () => {
		this.setState({ error:false, showDialog: false });
	};

	handleSubmit = async json => {
		/* very important to make MessageBar works correctly*/
		this.setState({ error: false, isSubmitting: true });
		
		let key_value_user = {first_name: null, last_name: null, user_name: null, password: null},
			key_value_user_activity = {user_id: null, group: null, activity: null, on: null};

		for(let key in json){
			if(key_value_user.hasOwnProperty(key)){
				key_value_user[key] = json[key];
				if(key === "password")
					key_value_user[key] = ""+sha256(""+sha512(key_value_user[key]));
			}
			else if(key_value_user_activity.hasOwnProperty(key)){
				key_value_user_activity[key] = json[key];
				if(key === "group")
					key_value_user_activity[key] = `[${json[key]}]`;
				else if(key === "on")
					key_value_user_activity[key] = `{"centre_email":[${'"' + json[key].join('","') + '"'}]}`;
			}
		}

		/* add some fields require by create User API */
		if(!key_value_user.email) key_value_user.email = key_value_user.user_name.replace(/ /g,"_") + "@noemail.com";

		/* call User API to create an user
		 * dispatch an action letting react-admin know a API call is ongoing
		 * 
		 * NOTE:
		 *	As we want to know when the new activity has been created in order to close the modal,
		 *	we use the dataProvider directly.
		*/
    	try{
    		fetchStart();
    		/* create user first */
    		let res = await dataProvider()(CREATE, "User", { data: key_value_user });
    		
    		/* assign user to resource that can manipulate on */
			/* add some fields require by assign User API */
			key_value_user_activity.user_id = res.data.user_id;
			key_value_user_activity.uri = "assign";
    		await dataProvider()(CREATE, "User", { data: key_value_user_activity });
	    	/*
			dataProvider()(CREATE, "User", { data: key_value_user })
			.then((res) => {
				this.setState({ error: false, showDialog: false, isSubmitting: false });
			})
			.catch(error => {
				this.setState({ error: true, error_message: error.message, isSubmitting: false});
			})
			.finally(() => {
				// letting react-admin know a API call has ended 
				// and call parent refresh hook (useRefresh)
				fetchEnd();
				this.props.refresh();
			});
			*/	
			this.setState({ error: false, showDialog: false, isSubmitting: false });
		}
		catch(error){
			//this.setState({ error: true, error_message: error.message, isSubmitting: false});
		}
		finally{
			//fetchEnd();
			//this.props.refresh();
		}
	};

	render() {
		const { error, error_message, showDialog, isSubmitting } = this.state;
		
		return(
			<Fragment>
				<Button 
					color="primary"
					startIcon={<AddIcon />}
					onClick={this.handleClick}
				>Add
				</Button>
				<Dialog
					fullWidth
					open={showDialog}
					onClose={this.handleCloseClick}
					aria-label="Add user"
				>
					<DialogTitle>Add user</DialogTitle>
					<DialogContent>
						<Form
						    onSubmit={this.handleSubmit}
						    render={({ handleSubmit, form, submitting, pristine/*, values*/ }) => (
					            // here starts the custom form layout
				            <form onSubmit={handleSubmit}>
				                <Box>
				                	<Box display="flex">
										<Box flex={1} mr="0.5em">
											<TextInput validate={[required(),  minLength(4)]} source="first_name" resource="User" fullWidth />
										</Box>
										<Box flex={1} ml="0.5em">
											<TextInput validate={[required(), minLength(4)]} source="last_name" resource="User" fullWidth />
										</Box>
									</Box>
									<Box display="flex">
										<Box flex={1} mr="0.5em">
											<TextInput validate={[required()]} source="user_name" resource="User" fullWidth />
										</Box>
										<Box flex={1} ml="0.5em">
											<PasswordInput validate={validatePassword} source="password" fullWidth inputProps={{ autocomplete: 'current-password' }} />
										</Box>
									</Box>

				                    <Box mt="0.2em" />
				                    
				                    <Box display="flex">
										<SelectArrayInput 
											validate={[required()]}
											fullWidth={true} 
											label="Select centre"
											name="on"
											optionValue="email"
											choices={this.arrCentre}/>
				                    </Box>

				                    <Box mt="0.2em" />
				                    
				                    <Box display="flex">
										<Field
											validate={FormRequired}
											className="box-fullwith"
											label="Role *"
											name="group" /* role */
											component={this.renderSelectField}
										>
											{this.renderSelectRole()}
										</Field>
				                    </Box>

				                	{error && <Box mt="0.2em" >
						                <MessageBar
						                	show={error}
									        variant="error"
									        message={error_message}
									    />
					                </Box>}
					            </Box>
				                <Toolbar>
				                    <Box display="flex" justifyContent="space-between" width="100%">
				                    	{/* SaveButton not submitting form, that why use materia;-ui button */}
							            <Button 
							            	disabled={isSubmitting || submitting || pristine} 
							            	type="submit"
							            	variant="contained" color="primary"
							            	startIcon={<SaveIcon />}
							            >Submit
							            </Button>
				                		<Button onClick={this.handleCloseClick}
				                			variant="outlined"
											startIcon={<CancelIcon />}
										>Cancel
										</Button>
				                    </Box>
				                </Toolbar>
				            </form>
					        )}
					    />
					</DialogContent>
				</Dialog>
			</Fragment>
		)
	}
}

export default UserCreateButton;