import { fetchUtils } from 'ra-core';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token'),
    	  centre_email = localStorage.getItem('centre_email');

    options.headers.set('token', `${token}`);
    options.headers.set('centre_email', `${centre_email}`);
    return fetchUtils.fetchJson(url, options);
};

export default httpClient;