import React, { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';

const AppMenu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    /* add more menu items 
     * https://stackoverflow.com/questions/57404053/react-admin-create-a-custom-page-which-can-be-accessed-from-menu-sidebar
    */
    let activity = localStorage.getItem("activity"),
        objActivity = activity ? JSON.parse(activity) : null;
    let arrActivityExclude = ["user", "centre"];
    return (
        <div>
            {resources
            .filter(resource => {
                let resLowerCase = resource.name.toLowerCase();
                if(['vmsactivity', 'group'].includes(resLowerCase)) return false;
                else if(objActivity){
                    if(arrActivityExclude.includes(resLowerCase))
                        return objActivity[resLowerCase] && objActivity[resLowerCase].includes("create");
                    else return true;
                }
                else return true;
            })
            .map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={(resource.options && resource.options.label) || resource.name}
                    leftIcon={resource.icon ? createElement(resource.icon) : null}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
            ))}
            {/* Addtional left resource item
            <MenuItemLink
                to="/custom-route"
                primaryText="Miscellaneous"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            */
            }
            {isXSmall && logout}
        </div>
    );
};

export default withRouter(AppMenu);