import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha512';

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';

const authProvider = (apiUrl) => {

    const auth = (apiUrl, type, params) => {
        // called when the user attempts to log in
        if (type === AUTH_LOGIN) {
            const { username, password } = params,
                    encrypted_password = ""+sha256(""+sha512(password));
            const request = new Request(`${apiUrl}/User/auth?user_name=${username}&password=${encrypted_password}`, {
                method: 'GET',
                headers: new Headers({ 'Content-Type': 'application/json' }),
            })
            return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({data}) => {
                if(data.user_name) localStorage.setItem('user_name', data.user_name);
                if(data.token) localStorage.setItem('token', data.token);
                if(data.group) localStorage.setItem('group', data.group);
                if(data.activity) localStorage.setItem('activity', JSON.stringify(data.activity));
                return Promise.resolve(data);
            });
        }
        // called when the user clicks on the logout button
        if (type === AUTH_LOGOUT) {
            localStorage.removeItem('user_name');
            localStorage.removeItem('token');
            localStorage.removeItem('group');
            localStorage.removeItem('activity');
            localStorage.removeItem('obj_data');
            localStorage.removeItem('centre_email');
            /* User admin login -> User -> logout.
             * Other user login -> system access User.
             * Workaround is to replace login URL when logout.
             * It isn't the nicest solution imo but it get's the job done.
            */
            window.location = '/#/login';
            return Promise.resolve();
        }
        // called when the API returns an error
        if (type === AUTH_ERROR) {
            const { status } = params;
            if (status === 401 /*|| status === 403 */) {
                localStorage.removeItem('token');
                return Promise.reject();
            }
            return Promise.resolve();
        }
        // called when the user navigates to a new location
        if (type === AUTH_CHECK) {
            return localStorage.getItem('token')
                ? Promise.resolve()
                : Promise.reject();
        }
        return Promise.reject('Unknown method');
    };

    return (type, params) => {
        return auth(apiUrl, type, params);
    };
};

export default authProvider;