import * as React from "react";
import {Slot} from "../components/Blocking/Slot";
import { Range } from "../components/Blocking/Range";

export const Blocking = () => {
    return (
        <div
            style={{ 
                display: "flex",
                flexFlow: "column wrap",
                rowGap: "28px",
            }}
        >
            <Slot />
            <Range />
        </div>
    )
}