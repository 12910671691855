import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MessageBox from './MessageBox'

export default function MessageBar(props) {
	const { className, message, variant, duration, show, ...other } = props;

	const [open, setOpen] = useState(show);

	/* Post-render lifecycle
	 * pass an empty array to useEffect, it’s only executed after the first render
	 * componentDidMount, componentWillUnmount: code in return part
	*/
	useEffect(() => {
		/* clean up code */
		return () => {
		}
	}, [])

	const handleClose = (event, reason) => {
		setOpen(false);
		if (reason === 'clickaway')
			return;
	};

	return (
		<div>
		  <Snackbar
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'center',
			}}
			open={open}
			autoHideDuration={duration ? duration : 6000}
			onClose={handleClose}
		  >
			<MessageBox
			  onClose={handleClose}
			  variant={variant}
			  className={className}
			  message={message}
			  {...other}
			/>
		  </Snackbar>
		</div>
	);
}