import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
/*import AppBar from '@material-ui/core/AppBar';*/
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {Field} from './Field';

function TabPanel(props) {
  const { children, value, index, padding = true, ...other } = props;

  return (
    <div
      role="panel"
      hidden={value !== index}
      id={`formfield-panel-${index}`}
      aria-labelledby={`formfield-tab-${index}`}
      {...other}
    >
		{(value === index) && padding && (
			<Box p={3}>
				<Typography>{children}</Typography>
			</Box>
		)}
		{value === index && (
			<Typography>{children}</Typography>
		)}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `formfield-tab-${index}`,
    'aria-controls': `formfield-panel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FieldPanel() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div className={classes.root}>
			{/*<AppBar position="static">*/}
				<Tabs value={value} onChange={handleChange} aria-label="formfield tabs">
					<Tab label="Sign-In Form" {...a11yProps(0)} style={{textTransform: 'none', fontSize:"1.1rem"}} />
				</Tabs>
			{/*</AppBar>*/}
			<TabPanel value={value} index={0} padding={false}>
				<Field header={false}/>
			</TabPanel>
		</div>
	);
}