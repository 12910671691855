import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudGetOne, UserMenu as RaUserMenu/*, MenuItemLink*/ } from 'react-admin';

class UserMenuView extends Component {
    componentDidMount() {
        /*
        this.fetchProfile();
        */
    }

    fetchProfile = () => {
        this.props.crudGetOne(
            // The resource
            'profile',
            // The id of the resource item to fetch
            'my-profile',
            // The base path. Mainly used on failure to fetch the data
            '/my-profile',
            // Wether to refresh the current view. I don't need it here
            false
        );
    };

    render() {
        const { /*crudGetOne, profile,*/ ...props } = this.props;
        return (
            <RaUserMenu label={localStorage.getItem("user_name")} {...props}>
            </RaUserMenu>
        );
        /*
        return (
            <RaUserMenu label={profile ? profile.nickname : ''} {...props}>
                <MenuItemLink
                    to="/my-profile"
                    primaryText="My profile"
                    leftIcon={<SettingsIcon />}
                />
            </RaUserMenu>
        );
        */
    }
}

const mapStateToProps = state => {
    const resource = 'profile';
    const id = 'my-profile';

    return {
        profile: state.admin.resources[resource]
            ? state.admin.resources[resource].data[id]
            : null
    };
};

const UserMenu = connect(
    mapStateToProps,
    { crudGetOne }
)(UserMenuView);
export default UserMenu;
