import axios from "axios";

let source =  axios.CancelToken.source();

const axiosInstance = axios.create({
	source: source,
	baseURL: "https://login.microsoftonline.com/",
	timeout: 28000,
	/*
	headers: {
		...(token && { token: token }),
        ...(centre_email && { centre_email: centre_email }),
	},
	*/
});

/* Intercept request, response and error handler
 * ref: https://auralinna.blog/post/2019/global-http-request-and-response-handling-with-the-axios-interceptor
*/
const isHandlerEnabled = (config={}) => {
	return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? false : true
}

const requestHandler = (request) => {
	if (isHandlerEnabled(request)) {
        /* For instance, an authentication token could be injected into all requests
         * request.headers['token'] = localStorage.getItem('token')
        */
	}
	return request
}

const successHandler = (response) => {
	if (isHandlerEnabled(response.config)) {
		/* additional error handler, for instance add more data */
	}
	/* ignore data.data */
	return response.data
}

const errorHandler = (error) => {
	if (isHandlerEnabled(error.config)) {
		/* additional error handler, for instance add more data */
	}
	/* return Promise.reject({ ...error }); */
	/* if back-end send custom error then alter axios error message with custom message
	* ref: https://stackoverflow.com/questions/57839551/how-to-have-custom-error-code-check-for-axios-response-interceptor
	*/
	if (error.response.data && error.response.data.message) {
		let customError = new Error(error.response.data.message);
		error.original_status = error.status;
		error.status = error.response.data.statusCode;
		/* add some properties to make it look like a regular axios error */
		customError.response = error;
		customError.request = error.request;
		customError.config = error.config;
		customError.isAxiosError = true; // or not
	
		return Promise.reject(customError);
	}
	else
		return Promise.reject(error);
}

/* Enable interceptors */
axiosInstance.interceptors.request.use(request => requestHandler(request));
axiosInstance.interceptors.response.use(
	response => successHandler(response),
	error => errorHandler(error)
)

const api = (method, route, body = null) => {
	/* original set token and centre_email in axiosInstance = axios.create 
	 * it will be cached due to module is loaded
	*/
	axiosInstance.defaults.headers["token"] = localStorage.getItem('token');
	axiosInstance.defaults.headers["centre_email"] = localStorage.getItem('centre_email');

    return axiosInstance({ method, url: route, data: body});
}

export default api;
