import React from 'react';
import { List, Datagrid, TextField, EmailField } from 'react-admin';
/* import JSONField from './components/Fields/JSONField'; */

export const CentreList = props => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <EmailField source="email" />
                <TextField source="uid" />
                <TextField source="name" />
            </Datagrid>
        </List>
    )
};